import React from "react";
import front1 from "../../assets/front_page.jpg"
import front2 from "../../assets/front_page_2.jpg"
import front3 from "../../assets/front_page_3.jpg"
import front4 from "../../assets/front_page_4.jpg"


import Education from "../../assets/education.jpg";
import Poverty from "../../assets/alleviation.jpg";
import Water from "../../assets/water.jpg"
import Girl from "../../assets/girl_child.jpg"
import Health from "../../assets/health.jpg"
import Gift from "../../assets/Send-gift-amico.svg"


const Home = () => {
    return (
        <>
            <title>Albarka Relief Foundation | Home</title>
            <div className="hero__background">
                <div className="big-hero flex">
                    <div className="big-hero__flex container">
                        <div className="big-hero__left">
                            <div className="big-hero__text">
                                <h1>Charity and Social Welfare</h1>
                                <p>Albarka Relief Foundation is a non-profit organization whose prime objectives are charity and social welfare</p>
                                <a href="https://paystack.com/pay/albarkarelieffoundation" target="_blank" rel="noreferrer">

                                    <button className="button">
                                        <span className="button__text">
                                            Donate
                                        </span>
                                        <svg className="button__svg" role="presentational" viewBox="0 0 600 600">
                                            <defs>
                                                <clipPath id="myClip">
                                                    <rect x="0" y="0" width="100%" height="50%" />
                                                </clipPath>
                                            </defs>
                                            <g clipPath="url(#myClip)">
                                                <g id="money">
                                                    <path d="M441.9,116.54h-162c-4.66,0-8.49,4.34-8.62,9.83l.85,278.17,178.37,2V126.37C450.38,120.89,446.56,116.52,441.9,116.54Z" fill="#699e64" stroke="#323c44" strokeMiterlimit="10" strokeWidth="14" />
                                                    <path d="M424.73,165.49c-10-2.53-17.38-12-17.68-24H316.44c-.09,11.58-7,21.53-16.62,23.94-3.24.92-5.54,4.29-5.62,8.21V376.54H430.1V173.71C430.15,169.83,427.93,166.43,424.73,165.49Z" fill="#699e64" stroke="#323c44" strokeMiterlimit="10" strokeWidth="14" />
                                                </g>
                                                <g id="creditcard">
                                                    <path d="M372.12,181.59H210.9c-4.64,0-8.45,4.34-8.58,9.83l.85,278.17,177.49,2V191.42C380.55,185.94,376.75,181.57,372.12,181.59Z" fill="#a76fe2" stroke="#323c44" strokeMiterlimit="10" strokeWidth="14" />
                                                    <path d="M347.55,261.85H332.22c-3.73,0-6.76-3.58-6.76-8v-35.2c0-4.42,3-8,6.76-8h15.33c3.73,0,6.76,3.58,6.76,8v35.2C354.31,258.27,351.28,261.85,347.55,261.85Z" fill="#ffdc67" />
                                                    <path d="M249.73,183.76h28.85v274.8H249.73Z" fill="#323c44" />
                                                </g>
                                            </g>
                                            <g id="wallet">
                                                <path d="M478,288.23h-337A28.93,28.93,0,0,0,112,317.14V546.2a29,29,0,0,0,28.94,28.95H478a29,29,0,0,0,28.95-28.94h0v-229A29,29,0,0,0,478,288.23Z" fill="#a4bdc1" stroke="#323c44" strokeMiterlimit="10" strokeWidth="14" />
                                                <path d="M512.83,382.71H416.71a28.93,28.93,0,0,0-28.95,28.94h0V467.8a29,29,0,0,0,28.95,28.95h96.12a19.31,19.31,0,0,0,19.3-19.3V402a19.3,19.3,0,0,0-19.3-19.3Z" fill="#a4bdc1" stroke="#323c44" strokeMiterlimit="10" strokeWidth="14" />
                                                <path d="M451.46,435.79v7.88a14.48,14.48,0,1,1-29,0v-7.9a14.48,14.48,0,0,1,29,0Z" fill="#a4bdc1" stroke="#323c44" strokeMiterlimit="10" strokeWidth="14" />
                                                <path d="M147.87,541.93V320.84c-.05-13.2,8.25-21.51,21.62-24.27a42.71,42.71,0,0,1,7.14-1.32l-29.36-.63a67.77,67.77,0,0,0-9.13.45c-13.37,2.75-20.32,12.57-20.27,25.77l.38,221.24c-1.57,15.44,8.15,27.08,25.34,26.1l33-.19c-15.9,0-28.78-10.58-28.76-25.93Z" fill="#7b8f91" />
                                                <path d="M148.16,343.22a6,6,0,0,0-6,6v92a6,6,0,0,0,12,0v-92A6,6,0,0,0,148.16,343.22Z" fill="#323c44" />
                                            </g>

                                        </svg>
                                    </button>
                                </a>
                            </div>
                        </div>
                        <div className="big-hero__right">
                            <div className="big-hero__photo ">

                                {/* <img src={front1} alt="Albarka Relief Foundation" className="relative" />
                                <img src={front2} alt="Albarka Relief Foundation" className="relative_bottom" /> */}
                                <img src={front2} alt="Albarka Relief Foundation" />

                                {/* <img src={front2} alt="Albarka Relief Foundation" /> */}
                                <img src={front3} alt="Albarka Relief Foundation" style={{ width: "15em", height: "15em", margin: "auto" }} />

                                <img src={front4} alt="Albarka Relief Foundation" style={{ width: "15em", height: "15em", margin: "auto" }} />



                                <img src={front1} alt="Albarka Relief Foundation" />


                            </div>
                        </div>
                    </div>
                </div>



            </div>

            <div className="services">
                <div className="container">
                    <div className="services__main">
                        <div className="has-shadow-text u-margin-bottom-large">
                            <h4 className="header-tertiary u-text-center">OUR SECTORS</h4>
                        </div>
                        <div className="services__text">
                            <h2>We focus on five key areas.</h2>
                            <p style={{ fontStyle: "italic" }}>“Give, even when you know you can get nothing back.”<br /> Yasmin Mogahed

                            </p>
                        </div>

                    </div>
                    <div className="services__list">

                        <div className="service first">

                            <div className="service__icon" style={{ backgroundImage: `url(${Education})` }}>
                            </div>
                            <div className="service__content">
                                <h1 className="title title--first">Education</h1>
                                <p className="title "> We open doors to brighter futures through learning</p>

                                <p className="service__text">
                                    Our education programs create safe, inclusive and quality learning environments for all genders and backgrounds, teach vocational skills geared towards the job market, and build local capacity through teacher training.
                                </p>
                                <a href="true" className="services__first--link">Learn more </a>
                            </div>

                        </div>

                        <div className="service second">

                            <div className="service__icon" style={{ backgroundImage: `url(${Poverty})` }}>
                            </div>
                            <div className="service__content">
                                <h1 className="title title--second">Poverty Alleviation</h1>
                                <p className="title "> We create income-generating pathways for marginalized groups</p>

                                <p className="service__text">
                                    Our programs foster enterprises and create jobs, connect people to markets, and empower families to purchase necessities through cash assistance programs.
                                </p>
                                <a href="true" className="services__second--link">Learn more </a>

                            </div>

                        </div>

                        <div className="service third">

                            <div className="service__icon" style={{ backgroundImage: `url(${Water})` }} >
                            </div>
                            <div className="service__content">
                                <h1 className="title title--third">Water</h1>
                                <p className="title "> We connect communites to clean water and sanitation</p>

                                <p className="service__text">
                                    Our teams teach basic hygiene practices, which go hand-in-hand with our programs that restore access to clean, reliable water sources. Together, these efforts are the building blocks of healthy lives and preventing diseases.
                                </p>
                                <a href="true" className="services__third--link">Learn more </a>

                            </div>

                        </div>

                        <div className="service">

                            <div className="service__icon" style={{ backgroundImage: `url(${Girl})` }}>
                            </div>
                            <div className="service__content">
                                <h1 className="title title--first">Women and Girl Child Empowerment</h1>
                                <p className="service__text">
                                    Millions of girls are subjected to abuse, child labor, trafficking, child marriage, and other offenses.

                                    Our teams provide programs protecting girls and women by equipping skilled, local staff to offer training, education, counseling, medical care, small business loans, and other programs that reach women and girls as well.




                                </p>
                                <a href="true" className="services__first--link">Learn more </a>

                            </div>

                        </div>


                        <div className="service">

                            <div className="service__icon" style={{ backgroundImage: `url(${Health})` }}>
                            </div>
                            <div className="service__content">
                                <h1 className="title title--second">Health</h1>
                                <p className="title "> We ensure access to quality healthcare</p>

                                <p className="service__text">
                                    Our teams provide various action-oriented prevention programs for communites threatened by outbreaks of disease.
                                </p>
                                <a href="true" className="services__second--link">Learn more </a>

                            </div>

                        </div>
                    </div>
                </div >

            </div >

            <div className="services">
                <div className="container">
                    <div className="services__main">
                        <div className="has-shadow-text u-margin-bottom-large">
                            <h4 className="header-tertiary u-text-center">OUR IMPACT</h4>
                        </div>
                        <div className="services__text">
                            <h2 style={{ color: "#00c0ea" }}>

                                Together, we create lasting change for communities in vulnerable situations.
                            </h2>
                            <p style={{ fontStyle: "italic" }}>

                                “Don’t feel ashamed when giving little for charity; that is because there is always goodness in giving no matter how little.”<br /> Ali Ibn Abi Talib (RA)



                            </p>
                        </div>

                    </div>
                </div >

            </div >

            <div className="services">
                <div className="container">
                    <div className="services__main">
                        <div className="has-shadow-text u-margin-bottom-large">
                            <h4 className="header-tertiary u-text-center">DONATE TO ALBARKA RELIEF FOUNDATION</h4>
                        </div>
                        <div className="services__text">
                            <h2 style={{ color: "#f1b104" }}>Make a difference today</h2>
                            <p style={{ fontStyle: "italic" }}>



                                “Those who spend in charity will be richly rewarded.”<br /> [Quran 75:10]

                            </p>
                        </div>

                    </div>
                    <div className="services__list">

                        <div className="service first">

                            <div className="service__icon" style={{ backgroundImage: `url(${Gift})` }}>
                            </div>
                            <div className="service__content">

                                <p className="service__text">
                                    With a gift to Albarka Relief Foundation, you can help deliver clean drinking water. You can help provide healthcare to displaced families, far from home. You can help ensure girls and boys alike receive an education, which in turn creates paths towards employment. This all starts with you.
                                </p>
                                <a href="https://paystack.com/pay/albarkarelieffoundation" target="_blank" rel="noreferrer">

                                    <button className="button">
                                        <span className="button__text">
                                            Donate
                                        </span>
                                        <svg className="button__svg" role="presentational" viewBox="0 0 600 600">
                                            <defs>
                                                <clipPath id="myClip">
                                                    <rect x="0" y="0" width="100%" height="50%" />
                                                </clipPath>
                                            </defs>
                                            <g clipPath="url(#myClip)">
                                                <g id="money">
                                                    <path d="M441.9,116.54h-162c-4.66,0-8.49,4.34-8.62,9.83l.85,278.17,178.37,2V126.37C450.38,120.89,446.56,116.52,441.9,116.54Z" fill="#699e64" stroke="#323c44" strokeMiterlimit="10" strokeWidth="14" />
                                                    <path d="M424.73,165.49c-10-2.53-17.38-12-17.68-24H316.44c-.09,11.58-7,21.53-16.62,23.94-3.24.92-5.54,4.29-5.62,8.21V376.54H430.1V173.71C430.15,169.83,427.93,166.43,424.73,165.49Z" fill="#699e64" stroke="#323c44" strokeMiterlimit="10" strokeWidth="14" />
                                                </g>
                                                <g id="creditcard">
                                                    <path d="M372.12,181.59H210.9c-4.64,0-8.45,4.34-8.58,9.83l.85,278.17,177.49,2V191.42C380.55,185.94,376.75,181.57,372.12,181.59Z" fill="#a76fe2" stroke="#323c44" strokeMiterlimit="10" strokeWidth="14" />
                                                    <path d="M347.55,261.85H332.22c-3.73,0-6.76-3.58-6.76-8v-35.2c0-4.42,3-8,6.76-8h15.33c3.73,0,6.76,3.58,6.76,8v35.2C354.31,258.27,351.28,261.85,347.55,261.85Z" fill="#ffdc67" />
                                                    <path d="M249.73,183.76h28.85v274.8H249.73Z" fill="#323c44" />
                                                </g>
                                            </g>
                                            <g id="wallet">
                                                <path d="M478,288.23h-337A28.93,28.93,0,0,0,112,317.14V546.2a29,29,0,0,0,28.94,28.95H478a29,29,0,0,0,28.95-28.94h0v-229A29,29,0,0,0,478,288.23Z" fill="#a4bdc1" stroke="#323c44" strokeMiterlimit="10" strokeWidth="14" />
                                                <path d="M512.83,382.71H416.71a28.93,28.93,0,0,0-28.95,28.94h0V467.8a29,29,0,0,0,28.95,28.95h96.12a19.31,19.31,0,0,0,19.3-19.3V402a19.3,19.3,0,0,0-19.3-19.3Z" fill="#a4bdc1" stroke="#323c44" strokeMiterlimit="10" strokeWidth="14" />
                                                <path d="M451.46,435.79v7.88a14.48,14.48,0,1,1-29,0v-7.9a14.48,14.48,0,0,1,29,0Z" fill="#a4bdc1" stroke="#323c44" strokeMiterlimit="10" strokeWidth="14" />
                                                <path d="M147.87,541.93V320.84c-.05-13.2,8.25-21.51,21.62-24.27a42.71,42.71,0,0,1,7.14-1.32l-29.36-.63a67.77,67.77,0,0,0-9.13.45c-13.37,2.75-20.32,12.57-20.27,25.77l.38,221.24c-1.57,15.44,8.15,27.08,25.34,26.1l33-.19c-15.9,0-28.78-10.58-28.76-25.93Z" fill="#7b8f91" />
                                                <path d="M148.16,343.22a6,6,0,0,0-6,6v92a6,6,0,0,0,12,0v-92A6,6,0,0,0,148.16,343.22Z" fill="#323c44" />
                                            </g>

                                        </svg>
                                    </button>
                                </a>
                            </div>

                        </div>
                    </div>
                </div >

            </div >



        </>
    )
}

export default Home