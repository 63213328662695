import React from "react";
import { NavLink } from 'react-router-dom';

import logoImg from "../../assets/Albarka_Logo_PNG.png";

const logo = props => {
    // let img = props.isMobile ? badgeImg : logoImg;
    return (
        <NavLink to="/">
            <div className="header__logo">
                <img src={logoImg} alt="Albarka Relief Foundation Logo" />
            </div>
        </NavLink>
    )
}

export default logo;