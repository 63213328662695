import React from "react";
import NavigationItem from "./NavigationItem";

let headerNavList = null;

const navigationItems = props => {
    headerNavList =
        <ul className="header__nav__list">
            {/* <NavigationItem link="what-we-do" classes={["header__nav__link"]}>What we do</NavigationItem>
            <NavigationItem link="who-we-are" classes={["header__nav__link"]}>Who we are</NavigationItem>
            <NavigationItem link="our-impact" classes={["header__nav__link"]}>Our Impact</NavigationItem> */}
            <NavigationItem link="get-involved" classes={["header__nav__link"]}>Get Involved</NavigationItem>
            <NavigationItem link="donate" classes={["header__nav__link"]}>Donate</NavigationItem>
        </ul>

    if (props.type === "mobile") {
        let mobileListClasses = ["header__mobile-nav__list"];
        if (props.showMenu) {
            mobileListClasses.push("show");
        }
        headerNavList = <ul className={mobileListClasses.join(" ")}>
            {/* <NavigationItem link="what-we-do" type="mobile" classes={["header__mobile-nav__link"]}>What we do</NavigationItem>
            <NavigationItem link="who-we-are" type="mobile" classes={["header__mobile-nav__link"]}>Who we are</NavigationItem>
            <NavigationItem link="our-impact" type="mobile" classes={["header__mobile-nav__link"]}>Impact</NavigationItem> */}
            <NavigationItem link="get-involved" type="mobile" classes={["header__mobile-nav__link"]}>Get Involved</NavigationItem>
            <NavigationItem link="donate" type="mobile" classes={["header__mobile-nav__link"]}>Donate</NavigationItem>
        </ul>
    };

    return headerNavList;
}

export default navigationItems;