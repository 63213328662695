import React from "react";
import { Switch } from 'react-router-dom';
import './sass/main.scss';
import Layout from './components/Layout';
import Routes from './Routes';

function App() {
  return (
    <Switch>
      <Layout>
        <Routes />
      </Layout>
    </Switch>

  );
}

export default App;
