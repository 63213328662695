import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../../assets/Albarka_Logo_PNG.png"
import { FiFacebook, FiInstagram } from "react-icons/fi"
import { ImWhatsapp } from "react-icons/im"
import { IoIosArrowUp } from "react-icons/io"
const footer = () => {
    const scrollUp = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }
    return (
        <footer className="footer" >

            <div className="container">

                <div className="footer__logo">
                    <img src={logo} alt="Albarka Relief Foundation" />
                </div>

                <ul className="footer__links">
                    <li>
                        <NavLink to="/">Terms of Service</NavLink>
                        <span className="dot">&bull;</span>
                    </li>
                    <li>
                        <NavLink to="/">Privacy Policy</NavLink>
                        <span className="dot">&bull;</span>
                    </li>

                    <li>
                        <NavLink to="/">Contact us</NavLink>
                    </li>
                </ul>

                <div className="footer__end" >
                    <p className="footer__end__copyright">&copy; 2020 Albarka Relief Foundation. All rights reserved.</p>
                    <div className="footer__end__socials">


                        <ul className="social-links">
                            <li className="">
                                <a href="https://wa.me/+2347061282749/?text=Hello" target="_blank" rel="noreferrer">

                                    <ImWhatsapp color="#41465a" />
                                </a>

                            </li>

                            <li className="">
                                <a href="https://www.facebook.com/albarkarelieffoundation" target="_blank" rel="noreferrer">

                                    <FiFacebook color="#41465a" />
                                </a>

                            </li>

                            <li className="">
                                <a href="https://www.instagram.com/albarkarelieffoundation" target="_blank" rel="noreferrer">

                                    <FiInstagram color="#41465a" />
                                </a>

                            </li>


                        </ul>
                    </div>
                    <div className="footer__end__up">
                        <div className="up-button" onClick={scrollUp}>
                            <IoIosArrowUp />

                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default footer;