import React from "react";
import { Route } from 'react-router-dom';

// Pages
import Home from '../pages/home';
import getInvolved from "../pages/getInvolved";
import donate from "../pages/donate";

const routes = () => {

    return (
        <React.Fragment>
            <Route component={Home} path="/" exact />
            <Route component={getInvolved} path="/get-involved" exact />
            <Route component={donate} path="/donate" exact />
        </React.Fragment>
    );
}

export default routes;