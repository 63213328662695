import React from "react";

const donate = () => {
    return (
        <>

            <title>Albarka Relief Foundation | Donate</title>

            <div className="services">
                <div className="container">
                    <div className="services__main">
                        <div className="has-shadow-text u-margin-bottom-large">
                            <h4 className="header-tertiary u-text-center">Donate to Albarka Relief Foundation</h4>
                        </div>
                        <p className="services__text">
                            With a gift to Albarka Relief Foundation, you can help deliver clean drinking water. You can help provide healthcare to displaced families, far from home. You can help ensure girls and boys alike receive an education, which in turn creates paths towards employment. This all starts with you.
                            <br />
                            You can donate to Albarka Relief Foundation <a href="https://paystack.com/pay/albarkarelieffoundation" style={{ color: "#f1b104" }} rel="noreferrer" target="_blank">
                                here
                            </a>
                        </p>
                    </div>

                    <div className="services__main">
                        <div className="has-shadow-text u-margin-bottom-large">
                            <h4 className="header-tertiary u-text-center">How our funds are spent</h4>
                        </div>
                        <p className="services__text">
                            89% of all donated funds go directly to our programs. Whether you’re donating for the first time, making a monthly donation, or joining our membership programs, your support helps us send relief to those who need it most
                        </p>
                    </div>
                </div>
            </div>

        </>
    )
}

export default donate